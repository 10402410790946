import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-preview"
export default class extends Controller {
  static targets = ["input", "previewContainer"];

  connect() {
    // Clear previews if necessary on connect
    this.previewContainerTarget.innerHTML = "";
  }

  preview() {
    const files = this.inputTarget.files;
    this.previewContainerTarget.innerHTML = ""; // Clear existing previews
    this.previewContainerTarget.classList.remove("hidden");

    Array.from(files).forEach(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = document.createElement("img");
        img.src = e.target.result;
        img.className = "h-20 w-20 object-cover rounded-md mr-2 mb-2";
        this.previewContainerTarget.appendChild(img);
      };
      reader.readAsDataURL(file);
    });
  }
}
