import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["latitude", "longitude", "radius", "map", "polygons"];
  currentCircle = null;
  polygons = [];

  connect() {
    if (typeof google !== 'undefined' && google.maps) {
      this.initMap();
    } else {
      document.addEventListener('google-maps-callback', () => this.initMap());
    }
  }

  initMap() {
    if (typeof google !== 'undefined' && google.maps) {
      const map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 40.0379, lng: -76.3055 },
        zoom: 12,
        mapTypeId: google.maps.MapTypeId.SATELLITE,  // Set the map to satellite view
        streetViewControl: false,  // Disable the Street View "pegman"
        fullscreenControl: false,
        rotateControl: 0
      });

      this.setMapCenter(map);

      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null, // Start with no drawing mode
        drawingControl: false, // Disable default control, using custom buttons instead
        circleOptions: {
          fillColor: "#00A1FB",
          fillOpacity: 0.3,
          strokeColor: "#00A1FB",
          strokeWeight: 2,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
        polygonOptions: {
          fillColor: "#00A1FB",
          fillOpacity: 0.3,
          strokeColor: "#00A1FB",
          strokeWeight: 3,
          clickable: false,
          editable: true,
          zIndex: 1,
        },
      });
      this.drawingManager.setMap(map);

      google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', () => {
        if (this.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.CIRCLE) {
          // Clear all polygons if starting to draw a circle
          this.polygons.forEach(polygon => polygon.setMap(null));
          this.polygons = [];
        } else if (this.drawingManager.getDrawingMode() === google.maps.drawing.OverlayType.POLYGON) {
          // Clear the circle if starting to draw a polygon
          if (this.currentCircle) {
            this.currentCircle.setMap(null);
            this.currentCircle = null;
          }
        }
      });

      google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
        if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
          // Replace the existing circle if there is one
          if (this.currentCircle) {
            this.currentCircle.setMap(null);
          }
          this.currentCircle = event.overlay;

          const circle = event.overlay;
          const center = circle.getCenter();
          const radius = circle.getRadius();
  
          this.latitudeTarget.value = center.lat();
          this.longitudeTarget.value = center.lng();
          this.radiusTarget.value = radius / 1609.34 // Convert meters to miles;

        } else if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          
          
          // Add the new polygon to the list of polygons
          this.polygons.push(event.overlay);

          this.polygonsData = [];
          this.polygons.forEach(polygon => {
            const vertices = polygon.getPath();
            let coordinates = [];
            for (let i = 0; i < vertices.getLength(); i++) {
              const xy = vertices.getAt(i);
              coordinates.push({ lon: xy.lng().toString(), lat: xy.lat().toString() });
            }
            // Close the polygon by repeating the first coordinate
            coordinates.push({ lon: vertices.getAt(0).lng().toString(), lat: vertices.getAt(0).lat().toString() });
            this.polygonsData.push(coordinates);
          });
            
          // Update the hidden field with the stringified version of all polygons
          this.polygonsTarget.value = JSON.stringify(this.polygonsData);
  
        }
        // Optional: Switch off the drawing mode after a shape is drawn
        this.drawingManager.setDrawingMode(null);
      });
    } else {
      console.error('Google Maps API not yet loaded');
    }
  }

  drawCircle() {
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
  }

  drawPolygon() {
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  }

  resetMap() {
    const map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 40.0379, lng: -76.3055 },
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.SATELLITE,  // Set the map to satellite view
      streetViewControl: false,  // Disable the Street View "pegman"
      fullscreenControl: false,
      rotateControl: 0
    });

    this.setMapCenter(map);
    this.drawingManager.setMap(map);
  }

  setMapCenter(mapToCenter) {
        // Try HTML5 geolocation
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            mapToCenter.setCenter(pos);
            alert('Your current location is: ' + pos.lat + ', ' + pos.lng);
    
          }, () => {
            mapToCenter.setCenter({ lat: 40.0379, lng: -76.3055 });
          });
        } else {
          mapToCenter.setCenter({ lat: 40.0379, lng: -76.3055 });
        }
  }

}
