import Swiper from "swiper";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "next", "prev"];
  static values = { activeKey: String };
  swiper = null;
  observer = null;

  connect() {
    this.initializeSwiper();
  }

  initializeSwiper() {
    this.swiper = new Swiper(this.containerTarget, {
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
    });

    this.waitForSlides();
  }

  waitForSlides() {
    if (this.swiper && this.swiper.slides.length > 0) {
      // Swiper is already initialized and slides are loaded
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.observer = new MutationObserver(mutations => {
        if (this.swiper && this.swiper.slides.length > 0) {
          this.observer.disconnect(); // Stop observing once slides are loaded
          resolve(); // Resolve the promise
        }
      });

      this.observer.observe(this.containerTarget, { childList: true, subtree: true });
    });
  }

  async navigate(event) {
    event.preventDefault();
    await this.waitForSlides();

    const { key } = event.detail;
    this.activeKeyValue = key["key"];
  }

  activeKeyValueChanged() {
    // Make sure swiper is initialized and has slides
    if (this.swiper && this.swiper.slides.length > 0) {
      const activeSlide = this.findActiveSlide();
      this.swiper?.slideTo(activeSlide, 0);
    }
  }

  findActiveSlide() {
    // Make sure swiper is initialized and has slides
    if (this.swiper && this.swiper.slides.length > 0) {
      return this.swiper.slides.findIndex(
        (slide) => slide.dataset.key == this.activeKeyValue
      );
    }
    return -1; // Return an invalid index if swiper is not ready
  }
}
