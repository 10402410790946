/* eslint no-console:0 */


import React from 'react';
import { createRoot } from 'react-dom/client';
import ComboBox from './components/ComboBox';

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
require("local-time").start()

// Start Rails UJS
Rails.start()


window.initGoogleMaps = function () {
  if (window.initMap) {
      initMap(); // Initialize Google Maps with Drawing Manager if available
  }
  if (window.initAutocomplete) {
      initAutocomplete(); // Initialize Places Autocomplete
  }
  
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  });
  window.dispatchEvent(event);
}

import Swiper from "swiper";
import { Navigation } from 'swiper/modules';
Swiper.use([Navigation]);


document.addEventListener('DOMContentLoaded', () => {
  const nodes = document.querySelectorAll('[data-react-component]');
  nodes.forEach(node => {
    const name = node.dataset.reactComponent;
    const props = JSON.parse(node.dataset.reactProps);
    if (name === 'ComboBox') {
      const handleSelect = selected => {
        console.log('Selected:', selected);
        // Handle the selected value
      };
      const root = createRoot(node);
      root.render(<ComboBox {...props} onSelect={handleSelect} />);
    }
  });
});