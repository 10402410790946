// import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   static targets = ["addressElement", "paymentElement", "error", "form"]
//   static values = {
//     clientSecret: String,
//     returnUrl: String,
//     name: String
//   }

//   connect() {
//     this.stripe = Stripe(this.stripeKey)
//     // let theme = document.documentElement.classList.contains("dark") ? "night" : "stripe";
//     let theme = "stripe";
//     this.elements = this.stripe.elements({
//       appearance: {
//         theme: theme,
//         variables: {
//           fontSizeBase: ".875rem",
//           colorPrimary: "#39a1fb"
//         }
//       },
//       clientSecret: this.clientSecretValue
//     })

//     this.paymentElement = this.elements.create("payment")
//     this.paymentElement.mount(this.paymentElementTarget)

//     if (this.hasAddressElementTarget) {
//       this.addressElement = this.elements.create('address', {
//         mode: 'billing',
//         defaultValues: {
//           name: this.nameValue
//         }
//       });
//       this.addressElement.mount(this.addressElementTarget)
//     }
//   }

//   changed(event) {
//     if (event.error) {
//       this.errorTarget.textContent = event.error.message
//     } else {
//       this.errorTarget.textContent = ""
//     }
//   }

//   async submit(event) {
//     event.preventDefault()
//     Rails.disableElement(this.formTarget)

//     const email = document.querySelector('#email').value;



//     // Payment Intents
//     if (this.clientSecretValue.startsWith("pi_")) {
//       const { error } = await this.stripe.confirmPayment({
//         elements: this.elements,
//         confirmParams: {
//           return_url: this.returnUrlValue,
//         },
//       });
//       this.showError(error)

//     // Setup Intents
//     } else {
//       const { error } = await this.stripe.confirmSetup({
//         elements: this.elements,
//         confirmParams: {
//           return_url: this.returnUrlValue,
//         },
//       });
//       this.showError(error)
//     }
//   }

//   showError(error) {
//     this.errorTarget.textContent = error.message
//     setTimeout(() => {
//       Rails.enableElement(this.formTarget)
//     }, 100)
//   }

//   get stripeKey() {
//     return document.querySelector('meta[name="stripe-key"]').getAttribute("content")
//   }
// }



import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addressElement", "paymentElement", "error", "form"]
  static values = {
    clientSecret: String,
    returnUrl: String,
    name: String,
    propertyId: Number
  }

  connect() {
    this.stripe = Stripe(this.stripeKey)
    let theme = "stripe";
    this.elements = this.stripe.elements({
      appearance: {
        theme: theme,
        variables: {
          fontSizeBase: ".875rem",
          colorPrimary: "#39a1fb"
        }
      },
      clientSecret: this.clientSecretValue
    })

    this.paymentElement = this.elements.create("payment")
    this.paymentElement.mount(this.paymentElementTarget)

    if (this.hasAddressElementTarget) {
      this.addressElement = this.elements.create('address', {
        mode: 'billing',
        defaultValues: {
          name: this.nameValue
        }
      });
      this.addressElement.mount(this.addressElementTarget)
    }
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  async submit(event) {
    event.preventDefault()
    Rails.disableElement(this.formTarget)

    const email = document.querySelector('#email').value;
    this.updatePropertyEmail(email)

    let paymentResult;
    if (this.clientSecretValue.startsWith("pi_")) {
      paymentResult = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
      });
    } else {
      paymentResult = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
      });
    }
  }

  updatePropertyEmail(email) {

    fetch(`/properties/${this.propertyIdValue}/update_email`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      body: JSON.stringify({ email: email })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        console.log('Property email updated successfully');
      } else {
        console.error('Error updating property email:', data.message);
      }
    });
  }

  get stripeKey() {
    return document.querySelector('meta[name="stripe-key"]').getAttribute("content")
  }
}