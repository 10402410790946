import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["input", "list", "option"];

  connect() {
    this.inputTarget.addEventListener("input", this.onInput.bind(this));
    this.optionTargets.forEach(option => {
      option.addEventListener("mouseenter", this.onMouseEnter.bind(this));
      option.addEventListener("mouseleave", this.onMouseLeave.bind(this));
    });
  }

  onInput(event) {
    const query = event.target.value.toLowerCase();
    this.filterOptions(query);
  }

  filterOptions(query) {
    this.optionTargets.forEach(option => {
      const text = option.textContent.toLowerCase();
      const isVisible = text.includes(query);
      option.style.display = isVisible ? "block" : "none";
    });
  }

  toggle(event) {
    const isVisible = this.listTarget.classList.contains("hidden");
    this.toggleList(isVisible);
  }

  toggleList(isVisible) {
    if (isVisible) {
      this.listTarget.classList.remove("hidden");
    } else {
      this.listTarget.classList.add("hidden");
    }
  }

  select(event) {
    const selectedOption = event.currentTarget;
    this.inputTarget.value = selectedOption.dataset.comboOptionValue;
    this.toggleList(false);
    this.optionTargets.forEach(option => option.classList.remove("font-semibold", "text-white", "bg-primary-600"));
    selectedOption.classList.add("font-semibold", "text-white", "bg-primary-600");
  }

  onMouseEnter(event) {
    const option = event.currentTarget;
    option.classList.add("text-white", "bg-primary-600");
  }

  onMouseLeave(event) {
    const option = event.currentTarget;
    option.classList.remove("text-white", "bg-primary-600");
  }
}